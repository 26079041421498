<template>
  <span>
    <el-button size="mini" type="danger" @click="start(ids)" :loading="loading">{{$t('button.delete')}}</el-button>
  </span>
</template>
<script>
import {del} from '@/api/user'
import {showErrorMsg, showSuccessMsg} from "@/utils/func";

export default {
  name: "DeleteUserBtn",
  props: {
    ids: {
      type: [String, Number, Array],
      default: '',
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    start(ids = []) {
      if (ids.length === 0) {
        showErrorMsg(this.$t('message.title'), this.$t('message.deleteNoData'))
        return
      }
      
      this.$confirm(this.$t('confirm.deleteBatch'), this.$t('message.title'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(() => {
        
        this.doDel(ids)
        
      }).catch(() => {
      });
    },
    doDel(ids = []) {
      this.loading = true
      del(ids).then(() => {
        showSuccessMsg(this.$t('message.title'), this.$t('message.deleteSuccess'))
        this.$emit('xzdListPageReload', false)
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    }
  },
  
}
</script>
<style scoped>
</style>