<template>
  <span style="text-align: center">
    <el-button size="mini" type="primary" @click="start(id)">{{id ? $t('button.edit') : $t('button.add')}}</el-button> &nbsp;
    <el-dialog
      :title="id ? $t('button.edit') : $t('button.add')"
      :visible.sync="dialogVisible"
      width="400px">
      <div v-loading="loading">
        <el-form
          ref="form"
          :rules="rules"
          :model="user"
          label-width="auto"
          size="medium">
          <el-form-item :label="$t('user.account')" prop="account">
            <el-input v-model="user.account" :placeholder="$ts('placeholder.input', 'user.account')"/>
          </el-form-item>
          <el-form-item :label="$t('user.password')" prop="password">
            <el-input v-model="user.password" :placeholder="$ts('placeholder.input', 'user.password')"/>
          </el-form-item>
          <el-form-item :label="$t('user.name')" prop="name">
            <el-input v-model="user.name" :placeholder="$ts('placeholder.input', 'user.name')"/>
          </el-form-item>
          <el-form-item :label="$t('user.realName')" prop="realName">
            <el-input v-model="user.realName" :placeholder="$ts('placeholder.input', 'user.realName')"/>
          </el-form-item>
          <el-form-item :label="$t('user.sort')" prop="sort">
            <el-input v-model="user.sort" :placeholder="$ts('placeholder.input', 'user.sort')"/>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <el-button size="medium" type="primary" @click="onSubmit" :loading="setIng">{{$t('button.confirm')}}</el-button>
        <el-button size="medium" @click="dialogVisible = false">{{$t('button.cancel')}}</el-button>
      </template>
    </el-dialog>
  </span>
</template>
<script>
import {detail, save} from '@/api/user'
import {showSuccessMsg} from "@/utils/func";

export default {
  name: "SaveUserBtn",
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      user: {
        type: 0,
        companyId: 0
      },
      rules: {
  
        account: [
          {required: true, message: this.$ts('placeholder.input', 'user.account'), trigger: 'blur'},
        ],
  
        password: [
          {required: true, message: this.$ts('placeholder.input', 'user.password'), trigger: 'blur'},
        ],
  
        name: [
          {required: true, message: this.$ts('placeholder.input', 'user.name'), trigger: 'blur'},
        ],
  
        realName: [
          {required: true, message: this.$ts('placeholder.input', 'user.realName'), trigger: 'blur'},
        ],
  
        sort: [
          {required: true, message: this.$ts('placeholder.input', 'user.sort'), trigger: 'blur'},
        ]
        
      },
      
      setIng: false,
    }
  },
  methods: {
    start(id) {
      this.dialogVisible = true
      this.loading = false
      this.user = {
        type: 0,
        companyId: 0
      }
      this.setIng = false
      if (id) {
        this.getInfo(id)
      }
    },
    getInfo(id) {
      this.loading = true
      detail({id}).then(res => {
        this.user = res.data
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          
          this.setIng = true
          let data = JSON.parse(JSON.stringify(this.user))
          save(data).then(() => {
            this.dialogVisible = false
            showSuccessMsg(this.$t('message.title'), (this.user.id ? this.$t('message.editSuccess') : this.$t('message.addSuccess')))
            this.$emit('xzdListPageReload', !this.user.id)
          }).finally(() => {
            setTimeout(() => {
              this.setIng = false
            }, 500)
          })
        }
      });
    }
  }
}
</script>
<style scoped>
</style>